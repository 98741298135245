define("discourse/plugins/discourse-motley-fool/discourse-motley-fool/templates/connectors/groups-form-membership-below-automatic/accessible-service-id", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="control-group">
    <label for="motley-fool-auto-assign">
      {{i18n "admin.accessible_services_desc"}}
    </label>
  
    {{input
      type="text"
      value=this.model.motley_fool_service_id
      class="motley-fool-accessible-service input-xxlarge"
    }}
  </div>
  */
  {
    "id": "Y2UFgB03",
    "block": "[[[10,0],[14,0,\"control-group\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,\"for\",\"motley-fool-auto-assign\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"admin.accessible_services_desc\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[1,[28,[35,3],null,[[\"type\",\"value\",\"class\"],[\"text\",[30,0,[\"model\",\"motley_fool_service_id\"]],\"motley-fool-accessible-service input-xxlarge\"]]]],[1,\"\\n\"],[13]],[],false,[\"div\",\"label\",\"i18n\",\"input\"]]",
    "moduleName": "discourse/plugins/discourse-motley-fool/discourse-motley-fool/templates/connectors/groups-form-membership-below-automatic/accessible-service-id.hbs",
    "isStrictMode": false
  });
});